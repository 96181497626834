import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import VerifiedIcon from './VerifiedIcon';

interface SuccessCardProps {
  imgUrl: string;
  story: string;
  author: string;
}

const SuccessCard: FC<SuccessCardProps> = ({ imgUrl, story, author }) => (
  <CardContainer>
    <ImageContainer>
      <img
        src={imgUrl}
        alt="A smiling girl holding a book and sitting next to her dog"
      />
    </ImageContainer>
    <ReviewDetails>
      <StoryContainer>
        <p>{story}</p>
      </StoryContainer>
      <CardFooter>
        <AuthorContainer>
          <strong>{author}</strong>
          <StarsContainer>
            <StarIcons starCount={5} />
          </StarsContainer>
        </AuthorContainer>
        <VerifiedBadge>
          <VerifiedIcon />
          <VerifiedText>Verified</VerifiedText>
        </VerifiedBadge>
      </CardFooter>
    </ReviewDetails>
  </CardContainer>
);

export default SuccessCard;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 343px;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #eeeef0;
  background: #fff6f2;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 309px;
  border-radius: 10px;
  margin-bottom: 12px;

  img {
    object-fit: cover;
    border-radius: 10px;
  }
`;

const ReviewDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const StoryContainer = styled.div`
  border-bottom: 1px solid rgba(142, 144, 154, 0.15);
  padding-bottom: 12px;

  p {
    color: #000 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.304px;
  }
`;

const AuthorContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;

  strong {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.14px;
    color: #000 !important;
  }
`;

const CardFooter = styled.div`
  margin-top: 12px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const StarsContainer = styled.div`
  display: flex;
`;

const VerifiedBadge = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 16px;
  background: #00a676;
`;

const VerifiedText = styled.div`
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;
  margin-left: 5px;
`;
