interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721894672/lc-1.mp4',
    title: 'I’m amazed',
    description: `"Lasting Change has been amazing for me. The tips and exercises have helped me build a consistent morning routine and stay motivated to stick with it."`,
    name: 'Emma R.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721894672/lc-2.mp4',
    title: 'Thank you!',
    description: `"This book is great! I used it to create a realistic fitness plan and have finally started seeing real progress in my personal growth and health."`,
    name: 'Alexandra',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721894670/lc-3.mp4',
    title: 'So motivational!',
    description: `"Such a motivational read! Lasting Change provided me with advice to quit procrastinating on my work projects, and my productivity has skyrocketed."`,
    name: 'Hillary V.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721894673/lc-4.mp4',
    title: 'Soo good',
    description: `"I've been able to develop a daily meditation practice that has significantly improved my mental clarity and stress levels. Thank you!"`,
    name: 'Tami',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1721975836/lc-5.mp4',
    title: 'My life has improved',
    description:
      '"This book has been a huge help in keeping me on track with my goals, especially with managing my time better after moving and finding a new job."',
    name: 'J.H.',
  },
];
