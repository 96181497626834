import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import VerifiedIcon from './VerifiedIcon';
import { tablet } from 'styles/breakpoints';

const ReviewCard: FC = () => (
  <CardContainer>
    <ImageContainer>
      <StyledImage
        src="https://res.cloudinary.com/drfweekiv/image/upload/v1721743111/Frame_1214134087.png"
        alt="Happy woman with a Lasting Change book"
      />
    </ImageContainer>
    <StyledText>
      &quot;The advice is relatable and down to earth. It helped me finally
      start my diet and exercise routine without feeling overwhelmed. The book
      made it much easier to stick to my goals and see real progress in just a
      few weeks!&quot;
    </StyledText>
    <UserRatingContainer>
      <div>
        <UserName>Johanna H., 25</UserName>
        <StarsContainer>
          <StarIcons starCount={5} />
        </StarsContainer>
      </div>
      <VerifiedBadge>
        <VerifiedIcon />
        <VerifiedText>Verified</VerifiedText>
      </VerifiedBadge>
    </UserRatingContainer>
  </CardContainer>
);

export default ReviewCard;

const CardContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 343px;
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #eeeef0;

  @media ${tablet} {
    margin-bottom: 16px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 313px;
  margin-bottom: 12px;
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
`;

const StyledText = styled.p`
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: -0.26%;
  color: #000000 !important;
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(142, 144, 154, 0.15);
`;

const UserRatingContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const VerifiedBadge = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #00a676;
  border-radius: 16px;
`;

const VerifiedText = styled.span`
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;
  color: #fff;
`;
const UserName = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.14px;
  margin-bottom: 8px;
  color: #000000 !important;
`;
const StarsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
