import React, { useEffect } from 'react';
import Header from '../../components/reviews/Header';
import MainReviewSection from 'components/reviews/MainReviewSection';
import VideoReviewsSection from 'components/reviews/VideoReviewsSection';
import BookOwnersSection from 'components/reviews/BookOwnersSection';
import SuccessStoriesSection from 'components/reviews/SuccessStoriesSection';
import styled from 'styled-components';
import Footer from 'components/reviews/Footer';
import { tablet } from 'styles/breakpoints';
import { Seo } from 'components';
import LCFooter from 'components/LCFooter';

const Reviews = () => {
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Seo
        title="Lasting Change Book Review"
        description="Discover how The Lasting Change has improved the lives of over 97% if users! Read The Lasting Change reviews, success stories, and customer insights"
        name="Lasting Change Book Review"
      />
      <Header isMobileApp={false} />
      <MainReviewSection />
      <VideoReviewsSection />
      <BookOwnersSection />
      <SuccessStoriesSection />
      <LCFooter
        disclaimerText={['© 2024 Lasting Change. All rights reserved.']}
      />
    </>
  );
};

export default Reviews;

export const StyledSection = styled.section<{
  backgroundColor: string;
  marginTop: string;
}>`
  height: fit-content;
  width: 100%;
  background: ${({ backgroundColor = '#FFF6F2' }) => backgroundColor};
  margin-top: ${({ marginTop = '69px' }) => marginTop};
  padding: 56px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${tablet} {
    padding: 24px 16px;
  }
`;

export const SectionHeading = styled.h1<{ marginBottom: string }>`
  color: #000;
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '8px' }) => marginBottom};
`;

export const SectionSubheading = styled.h2<{ marginBottom: string }>`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #595b60;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '10px' }) => marginBottom};
`;
