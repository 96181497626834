import React, { FC } from 'react';
import styled from 'styled-components';
import StarIcons from './StarIcons';
import { tablet } from 'styles/breakpoints';

interface UserReviewProps {
  author: string;
  comment: string;
}

const UserReviewCard: FC<UserReviewProps> = ({ author, comment }) => (
  <ReviewContainer>
    <Rating>
      <strong>{author}</strong>
      <div>
        <StarIcons starCount={5} />
      </div>
    </Rating>
    <p>{comment}</p>
  </ReviewContainer>
);

export default UserReviewCard;

const ReviewContainer = styled.div`
  display: flex;
  padding: 28px 20px;
  width: 100%;
  max-width: 343px;
  min-height: 198px;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  background: #fff;

  @media ${tablet} {
    padding: 12px;
  }

  p {
    color: #000000 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.304px;
  }
`;

const Rating = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  strong {
    color: #000000 !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }
`;
