interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Emily S.',
    comment:
      'I used to be messy and had zero discipline, but this book turned things around for me. I regained my confidence, thanks!',
  },
  {
    author: 'Laura K.',
    comment: `It provides practical advice and the personalized touch makes it even more impactful. I've already seen positive changes in my life.`,
  },
  {
    author: 'Sarah L.',
    comment: `Definitely one of the best self-growth books I’ve ever read. The exercises are super effective, and the advice is really inspiring. Love it!`,
  },
  {
    author: 'Amanda T.',
    comment:
      'This book helped me to not only to stick to my ruitine with daily exercise, reading and healthy meals, but helped me quit smoking after 10 years of it!!!!',
  },
  {
    author: 'Cassandra L.',
    comment:
      'It’s been a huge help. I doubled my income, and now I can finally travel more, just like I always dreamed. I can’t believe I could to this.',
  },
  {
    author: 'Karen D.',
    comment:
      'Really helped with my weight and self-esteem problems that I had. Never thought that I could get rid of that kind of baggage.',
  },
];
